import React, { useEffect, useState } from 'react';
import styles from './StudentDashboard.module.css';
import { getToken } from '../../Helpers/constants';

function StudentDashboard() {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordering, setOrdering] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const token = getToken();

  useEffect(() => {
    const fetchStudents = async () => {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append(
        'Authorization',
        `Bearer ${token}`
      );
      myHeaders.append('X-CSRFToken', 'dbulTVCCssdH35VRb2jUALKF7FHDu8ib2o2wyfbUuMzhZXQ0PLSvAgBOTQSwdOYi');

      const url = new URL('https://depiback.eyouthlearning.com/api/admin/students/');
      url.searchParams.append('coaching_rounds__code', searchTerm);
      url.searchParams.append('ordering', ordering);
      url.searchParams.append('page', page);
      url.searchParams.append('page_size', pageSize);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        setStudents(data.results);
        setTotalCount(data.count);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, [searchTerm, ordering, page, pageSize]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to first page when search term changes
  };

  const handleOrderingChange = (e) => {
    setOrdering(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  return (
    <div className={styles.container}>

      <div className={styles.controls}>
        <input
          type="text"
          placeholder="Search by coaching rounds"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select value={ordering} onChange={handleOrderingChange}>
          <option value="">Order by</option>
          <option value="meeting_count">Meeting Count</option>
          <option value="no_of_tasks">Number of Tasks</option>
        </select>
        <select value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5 per page</option>
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
        </select>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            {/* <th>User</th> */}
            <th>Coaching Round</th>
            <th>Job Profile Round</th>
            <th>Instructor</th>
            <th>Freelance Profiles</th>
            <th>Meeting Count</th>
            <th>Tasks Completed</th>
          </tr>
        </thead>
        <tbody>
          {students?.map((student) => (
            <tr key={student.id}>
              <td>{student.id}</td>
              {/* <td>{student.user}</td> */}
              <td>{student.coaching_round}</td>
              <td>{student.job_profile_round}</td>
              <td>{student.instructor}</td>
              <td>{student.all_freelance_platforms_profile_links.join(', ')}</td>
              <td>{student.meeting_count}</td>
              <td>
                {student.no_of_finished_tasks} / {student.no_of_tasks}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.pagination}>
        {Array.from({ length: Math.ceil(totalCount / pageSize) }, (_, i) => i + 1).map((pageNum) => (
          <button
            key={pageNum}
            onClick={() => handlePageChange(pageNum)}
            className={pageNum === page ? styles.active : ''}
          >
            {pageNum}
          </button>
        ))}
      </div>
    </div>
  );
}

export default StudentDashboard;
