import { Person as PersonIcon, Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Alert,
    Button,
    IconButton,
    InputAdornment,
    InputLabel,
    Stack,
    TextField
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { loginUser } from "../../API/Api"; 
import { useNavigate } from "react-router-dom";
import DepiLogo from "../../assets/depiEN.png";
import EYouthLogo from "../../assets/logo2.png";
import styles from "./Login.module.css";

// Function to refresh token
// eslint-disable-next-line
const refreshToken = async (refreshToken) => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-CSRFToken", "BoxKNWiVfvhecV7czR1549o9zjixc0dpr3SQxbmiHjXUGgQpf3kjT3zpyHzFF8mJ");

    const raw = JSON.stringify({ refresh: refreshToken });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    try {
        const response = await fetch("https://depiback.eyouthlearning.com/api/accounts/token/refresh/", requestOptions);
        const result = await response.json();
        if (response.ok) {
            localStorage.setItem("access_token", result.access);
            localStorage.setItem("refresh_token", result.refresh);
            console.log("Token refreshed successfully!", result.access);  // Console log for token refresh
        } else {
            console.error("Failed to refresh token:", result);
        }
    } catch (error) {
        console.error("Error refreshing token:", error);
    }
};

const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate(); 

    const validationSchema = Yup.object({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        password: Yup.string()
            .min(4, "Password must be at least 4 characters")
            .required("Password is required"),
    });

    const formik = useFormik({
        initialValues: { email: "", password: "" },
        validationSchema: validationSchema,

        onSubmit: async (values) => {
            try {
                const res = await loginUser(values.email, values.password); 
                if (res.access) {
                    localStorage.setItem("access_token", res.access);
                    localStorage.setItem("refresh_token", res.refresh);
                    localStorage.setItem("role", res.role);
                    
                    console.log("Access token received:", res.access); // Log the received access token

                    // const refreshInterval = setInterval(() => {
                    //     const refreshTokenValue = localStorage.getItem("refresh_token");
                    //     refreshToken(refreshTokenValue);
                    // }, 14 * 60 * 1000); // Refresh token every 14 minutes

                    if (res.role === "student") {
                        navigate("/user-profile");
                    } else if (res.role === "me") {
                        navigate("/student-dashboard");
                    } else if (res.role === "staff") {
                        navigate("/student-dashboard-preview");
                    } else {
                        navigate("/instructor-profile");
                    }
                } else {
                    setAlertMessage("Invalid credentials. Please try again.");
                    setAlert(true);
                }
            } catch (error) {
                console.error("Login failed:", error);
                setAlertMessage("An error occurred. Please try again later.");
                setAlert(true);
            }
        },
    });

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginForm}>
                <div className={styles.logoContainer}>
                    <img src={EYouthLogo} alt="EYouth" className={styles.logo1} />
                    <img src={DepiLogo} alt="Depi" className={styles.logo2} />
                </div>
                <h1>Login</h1>
                <form onSubmit={formik.handleSubmit} className="w-100 d-flex justify-content-center flex-column align-items-center">
                    <div className="d-flex gap-2 flex-column w-50 justify-content-center align-items-center">
                        <div className="d-flex justify-content-start w-100">
                            <InputLabel htmlFor="email" className={styles.inputLabel}>Email</InputLabel>
                        </div>
                        <TextField
                            fullWidth
                            focused
                            color="primary"
                            id="email"
                            name="email"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            className={styles.test}
                        />

                        <div className="d-flex justify-content-start w-100">
                            <InputLabel htmlFor="password" className={styles.inputLabel}>Password</InputLabel>
                        </div>
                        <TextField
                            fullWidth
                            focused
                            color="primary"
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            className={styles.togglePassword}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </div>
                    <div className="my-3 w-50">
                        <Button type="submit" className={styles.submitBtn} fullWidth variant="contained">Login</Button>
                    </div>
                </form>
                {alert && (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{alertMessage}</Alert>
                    </Stack>
                )}
            </div>
        </div>
    );
};

export default LoginPage;
