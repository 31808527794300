import React, { useEffect, useState } from 'react';
import { getToken } from '../../Helpers/constants';
import { useParams } from 'react-router-dom';
import styles from './WebinarAttendance.module.css'; // Importing the CSS module
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function WebinarAttendance() {
  const [attendees, setAttendees] = useState([]);
  const [attendList, setAttendList] = useState([]);
  const [notAttendList, setNotAttendList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const token = getToken();
  const { id } = useParams();

  useEffect(() => {
    fetchAttendees();
  }, [id, token]);

  const fetchAttendees = () => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "Te8erCmOuvoTZyJKwIIqLigGhfuAD6DvE1vHBjfNpcsDCdZWNGMVCe80tgDHXQkw");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`https://depiback.eyouthlearning.com/api/webinar/waiting-for-attendance/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setAttendees(result);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const handleStatusChange = (attendeeId, status) => {
    if (status === 'attend') {
      setAttendList((prev) => [...prev, attendeeId]);
      setNotAttendList((prev) => prev.filter((id) => id !== attendeeId));
    } else if (status === 'not_attend') {
      setNotAttendList((prev) => [...prev, attendeeId]);
      setAttendList((prev) => prev.filter((id) => id !== attendeeId));
    }
  };

  const handleSubmit = () => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-CSRFToken", "Te8erCmOuvoTZyJKwIIqLigGhfuAD6DvE1vHBjfNpcsDCdZWNGMVCe80tgDHXQkw");

    const raw = JSON.stringify({
      "webinar_id": id,
      "joined_student_ids": attendList,
      "not_joined_student_ids": notAttendList
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://depiback.eyouthlearning.com/api/webinar/add-attendance/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setOpenSnackbar(true); // Show success message
        fetchAttendees(); // Fetch updated attendees list
        resetLists(); // Reset the selected lists
      })
      .catch((error) => console.error('Error updating attendance:', error));
  };

  const resetLists = () => {
    setAttendList([]);
    setNotAttendList([]);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div>
      <h1 className={styles.webinarList}>Webinar Attendance</h1>

      {/* Success Snackbar */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Attendance status updated successfully!
        </MuiAlert>
      </Snackbar>

      {/* Button and Note */}
      <div className={styles.buttonSubmit}>
        <span className={styles.note}>
          Note: You should select attendance status  and then click on the submit button.
        </span>

        <button
          className={styles.submitButton}
          onClick={handleSubmit}
          disabled={attendList.length === 0 && notAttendList.length === 0}
        >
          Submit Attendance
        </button>
      </div>

      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {attendees.length > 0 ? (
            attendees.map((attendee) => (
              <tr key={attendee.id}>
                <td>{attendee.id}</td>
                <td>{attendee.full_name}</td>
                <td>{attendee.email}</td>
                <td>
                  <select
                    onChange={(e) => handleStatusChange(attendee.id, e.target.value)}
                    defaultValue=""
                  >
                    <option value="" disabled>Select Status</option>
                    <option value="attend">Attend</option>
                    <option value="not_attend">Not Attend</option>
                  </select>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No attendees found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default WebinarAttendance;
