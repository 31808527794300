import React, { useState, useEffect } from 'react';
import styles from './ProfileUser.module.css';
import { getToken } from '../../Helpers/constants';
import upWork from "../../assets/upwork.png";
import fiverr from "../../assets/fiver.png";
import khamsat from "../../assets/khmsat.png";
import nabbesh from "../../assets/nbeesh.jpg";
import tasmeemme from "../../assets/tsmem.png"

// Mapping of platform names to logo URLs
const platformLogos = {
  Upwork: upWork, 
  Fiverr: fiverr, 
  Khamsat: khamsat, 
  Nabbesh: nabbesh, 
  Freelancer: "https://cdn6.f-cdn.com/build/icons/fl-logo.svg",  
  Ureed: "https://ureed.com/favicon.ico",
  Tasmeemme: tasmeemme, 
  Bawaba: "https://www.albawaba.com/sites/default/themes/zing/images/favicon.ico", // not working
  FlexJobs: "https://www.flexjobs.com/favicon.ico",
  Mostaql: "https://mostaql.hsoubcdn.com/assets/favicons/favicon.ico", //not working
  Other: "https://via.placeholder.com/50", 
};

function ProfileUser() {
  const [userData, setUserData] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [newPlatform, setNewPlatform] = useState('');
  const [platformId, setPlatformId] = useState(''); // Dropdown selection state
  const [availablePlatforms, setAvailablePlatforms] = useState([]); // Platforms from the new API
  const [token, setToken] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const dynamicToken = getToken();
    setToken(dynamicToken);
    fetchUserData(dynamicToken);
    fetchPlatformLinks(dynamicToken); // Fetch existing platform links
    fetchExternalPlatforms(dynamicToken); // Fetch platforms from the new API
  }, []);

  const fetchUserData = (token) => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch('https://depiback.eyouthlearning.com/api/accounts/profile/', requestOptions)
      .then((response) => response.json())
      .then((result) => setUserData(result))
      .catch((error) => console.error(error));
  };

  const fetchPlatformLinks = (token) => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch('https://depiback.eyouthlearning.com/api/freelance-profile-links/', requestOptions)
      .then((response) => response.json())
      .then((result) => setPlatforms(result))
      .catch((error) => console.error(error));
  };

  // API to fetch freelance platforms (for dropdown)
  const fetchExternalPlatforms = (token) => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch('https://depiback.eyouthlearning.com/api/platforms/', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAvailablePlatforms(result); // Populate the dropdown menu
      })
      .catch((error) => console.error('Error fetching external platforms:', error));
  };

  const postPlatformLink = () => {
    if (newPlatform.trim() === '' || platformId === '') {
      setError('Link and platform must be selected');
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const raw = JSON.stringify({ url: newPlatform, platform: platformId });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch('https://depiback.eyouthlearning.com/api/freelance-profile-links/', requestOptions)
      .then((response) => response.json())
      .then(() => {
        setNewPlatform('');
        setPlatformId(''); // Reset dropdown
        fetchPlatformLinks(token); // Refresh the links after posting
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <h2 className={styles.title}>User Profile</h2>
      <div className={styles.profileContainer}>
        {userData ? (
          <div className={styles.userInfo}>
            <div className={styles.userCard}>
              <p><strong>Email:</strong> {userData?.user?.email || "N/A"}</p>
            </div>
            <div className={styles.userCard}>
              <p><strong>Phone:</strong> {userData?.phone || "N/A"}</p>
            </div>
            <div className={styles.userCard}>
              <p><strong>Coaching Round:</strong> {Array.isArray(userData?.coaching_rounds) ? userData.coaching_rounds.join(', ') : userData?.coaching_rounds}</p>
            </div>
            {userData?.job_profile_round && (
              <div className={styles.userCard}>
                <p><strong>Job Round Code:</strong> {userData?.job_profile_round?.code || "N/A"}</p>
                <p><strong>Community Link:</strong> <a href={userData?.job_profile_round?.community_link || "#"} target="_blank" rel="noopener noreferrer">{userData?.job_profile_round?.community_link || "N/A"}</a></p>
              </div>
            )}
            <div className={styles.userCard}>
              <p><strong>Technical Provider:</strong> {userData?.technical_provider || "N/A"}</p>
            </div>
            <div className={styles.userCard}>
              <p><strong>Training Round:</strong> {userData?.training_round || "N/A"}</p>
            </div>
            <div className={styles.userCard}>
              <p><strong>Job Profile:</strong> {userData?.job_profile || "N/A"}</p>
            </div>
            <div className={styles.userCard}>
              <p><strong>Track:</strong> {userData?.track || "N/A"}</p>
            </div>

            <div className={styles.linksSection}>
              <h1>Freelance account links:</h1>
              <ul className={styles.linkList}>
                {platforms.map((platform, index) => (
                  <li key={index} className={styles.linkCard}>
                    <a href={platform.url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={platformLogos[platform.platform_name] || platformLogos["Other"]}
                        alt={platform.platform_name}
                        className={styles.platformLogo}
                        title={platform.platform_name}  
                      />
                    </a>
                  </li>
                ))}
              </ul>
              {platforms.length < 3 && (
                <p className={styles.warning}>You must submit at least 3 links.</p>
              )}
            </div>
          </div>
        ) : (
          <p>Loading user data...</p>  // Display this when data is loading or null
        )}

        <div className={styles.platformsSection}>
          <h3>Add Your Accounts on Freelance Platforms</h3>
          <div className={styles.inputContainer}>

            <input
              type="text"
              value={newPlatform}
              onChange={(event) => {
                setNewPlatform(event.target.value);
                setError('');
              }}
              placeholder="Enter freelance platform link"
              className={styles.inputField}
            />

            <select
              value={platformId}
              onChange={(event) => setPlatformId(event.target.value)}
              className={styles.selectField}
            >
              <option value="">Select Platform</option>
              {availablePlatforms.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={postPlatformLink}
            className={styles.addButton}
            disabled={!newPlatform.trim() || !platformId}
          >
            Submit Link
          </button>
          {error && <p className={styles.error}>{error}</p>}
        </div>
      </div>
    </>
  );
}

export default ProfileUser;
