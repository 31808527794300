import React, { useEffect, useState } from 'react';
import { getTasks, submitTask } from '../../API/Api';
import styles from './Tasks.module.css';
import { getToken } from '../../Helpers/constants';

const TasksPage = () => {
    const [completeTasks, setCompleteTasks] = useState([]);
    const [uncompleteTasks, setUncompleteTasks] = useState([]);
    const [file, setFile] = useState({}); // State for file input for each task
    const [urls, setUrls] = useState({}); // State for URL input for each task
    const [errors, setErrors] = useState({}); // State for tracking errors
    const token = getToken();

    useEffect(() => {
        async function fetchTasks() {
            try {
                const tasksData = await getTasks(token);
                setCompleteTasks(tasksData.complete_tasks);
                setUncompleteTasks(tasksData.uncomplete_tasks);
            } catch (error) {
                console.error("Failed to fetch tasks:", error);
            }
        }

        fetchTasks();
    }, [token]);

    const validateUrl = (url) => {
        const urlPattern = /^https:\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
        return urlPattern.test(url);
    };

    const handleFileChange = (taskId, event) => {
        setFile({ ...file, [taskId]: event.target.files[0] });
    };

    const handleUrlChange = (taskId, event) => {
        setUrls({ ...urls, [taskId]: event.target.value });
    };

    const handleSubmit = async (taskId) => {
        // Validate URL
        if (!validateUrl(urls[taskId])) {
            setErrors({ ...errors, [taskId]: "Please enter a valid URL that starts with https://" });
            return;
        }

        try {
            if (!file[taskId] || !urls[taskId]) {
                setErrors({ ...errors, [taskId]: "Please upload a file and provide a URL." });
                return;
            }
            // eslint-disable-next-line 
            const result = await submitTask(taskId, file[taskId], urls[taskId], token);
            // console.log("Comment and grade submitted:", result);
            // Reload the page after successful submission
            window.location.reload();
        } catch (error) {
            console.error("Error submitting task:", error);
        }
    };

    return (
        <div className={styles.tasksContainer}>
            <h1 className={styles.title}>Weekly Tasks</h1>

            {/* Incomplete Tasks Section */}
            <h2>Incomplete Tasks</h2>
            {uncompleteTasks.length > 0 ? (
                uncompleteTasks.map((task) => (
                    <div key={task.id} className={styles.taskCard}>
                        <h2>{task.name}</h2>
                        <p><strong>Description:</strong> {task.description}</p>
                        <p><strong>Deadline:</strong> {new Date(task.deadline).toLocaleString()}</p>

                        {/* Task Submission Form */}
                        <form className={styles.submitForm} onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(task.id);
                        }}>
                            <input
                                type="file"
                                onChange={(e) => handleFileChange(task.id, e)}
                                className={styles.fileInput}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Enter URL"
                                value={urls[task.id] || ""}  // Use the task-specific URL
                                onChange={(e) => handleUrlChange(task.id, e)}
                                className={styles.urlInput}
                                required
                            />
                            {errors[task.id] && <h3 className={styles.errorText}>{errors[task.id]}</h3>}
                            <button type="submit" className={styles.submitButton}>Submit Task</button>
                        </form>
                    </div>
                ))
            ) : (
                <p>No incomplete tasks.</p>
            )}

            {/* Completed Tasks Section */}
            <h2>Completed Tasks</h2>
            {completeTasks.length > 0 ? (
                completeTasks.map((task) => (
                    <div key={task.id} className={styles.taskCard}>
                        <h2>{task.name}</h2>
                        <p><strong>Description:</strong> {task.description}</p>
                        <p><strong>Deadline:</strong> {new Date(task.deadline).toLocaleString()}</p>

                        {/* Review Section */}
                        <div className={styles.reviewSection}>
                            <p><strong>Review Comment:</strong> {task.review.comment}</p>
                            <p><strong>Review Degree:</strong> {task.review.degree}</p>
                        </div>
                    </div>
                ))
            ) : (
                <p>No completed tasks.</p>
            )}
        </div>
    );
};

export default TasksPage;
