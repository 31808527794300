import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import styles from './ControlPanel.module.css';
import AddMeeting from '../AddMeeting/AddMeeting';
import WebinarAttendance from '../WebinarAttendance/WebinarAttendance';
import AddWebinar from '../AddWebinar/AddWebinar';
import UploadStudents from '../UploadStudents/UploadStudents';

// Import components
// import AddWebinar from '../AddWebinar/AddWebinar';
// import UploadStudents from '..UploadStudents/UploadStudents';
// import WebinarAttendance from '../WebinarAttendance/WebinarAttendance';
// import AddMeeting from '../AddMeeting/AddMeeting';


function ControlPanel() {
  return (
    <div className={styles.dashboard}>
      <aside className={styles.sidebar}>
        <ul>
          <NavLink to="/control-panel/upload-students" className={({ isActive }) => isActive ? styles.activeLink : ''}> <li>Students</li></NavLink>
          <NavLink to="/control-panel/add-webinar" className={({ isActive }) => isActive ? styles.activeLink : ''}><li> Webinars</li></NavLink>
          {/* <NavLink to="/control-panel/webinar-attendance" className={({ isActive }) => isActive ? styles.activeLink : ''}> <li>Webinar Attendance</li></NavLink> */}
          <NavLink to="/control-panel/add-meeting" className={({ isActive }) => isActive ? styles.activeLink : ''}>  <li> Meetings</li></NavLink>
        </ul>
      </aside>
      <main className={styles.mainContent}>
        <Routes>
          <Route path="/upload-students" element={<UploadStudents />} />
          <Route path="/add-webinar" element={<AddWebinar />} />
          <Route path="/webinar-attendance/:id" element={<WebinarAttendance />} />
          <Route path="/add-meeting" element={<AddMeeting />} />
        </Routes>
      </main>
    </div >
  );
}

export default ControlPanel;
