import React, { useEffect, useState } from 'react';
import styles from './Webinar.module.css';
import { getToken } from '../../Helpers/constants';

const WebinarPage = () => {
    const [webinarData, setWebinarData] = useState(null);
    const [token, setToken] = useState('');

    useEffect(() => {
        // Simulate retrieving the token dynamically
        const dynamicToken = getToken(); // Replace with your dynamic token logic
        setToken(dynamicToken);

        // Fetch webinar data when the component mounts
        fetchWebinarData(dynamicToken);
    }, []);

    const fetchWebinarData = (token) => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', 'xmhB9v3mimkr2b6h9gijvOYTeo2fsmKKmzPMOPCEkGG1Y31qNZRUvjP20zd8b2qR');

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch('https://depiback.eyouthlearning.com/api/webinars/', requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // Assuming the API returns an array of webinars, we'll take the first one for this example
                if (result.length > 0) {
                    setWebinarData(result[0]);
                } else {
                    console.error('No webinars found');
                }
            })
            .catch((error) => console.error('Error fetching webinars:', error));
    };

    return (
        <div>
            <div className={styles.webinarContainer}>
                <h1 className={styles.title}>Webinar</h1>
                {webinarData ? (
                    <div className={styles.webinarCard}>
                        <h2>{webinarData.name}</h2>
                        <p><strong>Date:</strong> {webinarData.date}</p>
                        <p><strong>Time:</strong> {webinarData.time}</p>
                        <p><strong>Job Profile Round:</strong> {webinarData.job_profile_round}</p>
                        <a href={webinarData.link} target="_blank" rel="noopener noreferrer" className={styles.webinarLink}>
                            Join Webinar
                        </a>
                    </div>
                ) : (
                    <p>No webinar details yet</p>
                )}
            </div>
        </div>
    );
};

export default WebinarPage;
