import React, { useState, useEffect } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Card,
    CardContent,
    Typography,
} from '@mui/material';
import { getToken } from '../../Helpers/constants';
import styles from './MeetingInstructor.module.css';

const MeetingInstructor = () => {
    const [meetings, setMeetings] = useState([]);
    const [attendances, setAttendances] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const token = getToken();

    useEffect(() => {
        fetchMeetings();
        fetchAttendances();
        //eslint-disable-next-line
    }, [token]);

    const fetchMeetings = async () => {
        const myHeaders = new Headers({
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
        });

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        try {
            const response = await fetch("https://depiback.eyouthlearning.com/api/meetings/", requestOptions);
            const result = await response.json();
            setMeetings(result);
        } catch (error) {
            console.error("Error fetching meetings:", error);
        }
    };

    const fetchAttendances = async () => {
        const myHeaders = new Headers({
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
        });

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        try {
            const response = await fetch("https://depiback.eyouthlearning.com/api/attendances/", requestOptions);
            const result = await response.json();
            setAttendances(result);
        } catch (error) {
            console.error("Error fetching attendances:", error);
        }
    };

    // Handle tab changes
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Render Meetings based on tab selection
    const renderMeetings = (meetingsList) => {
        return (
            <Box className={styles.detailsSection}>
                {meetingsList.length > 0 ? (
                    meetingsList.map((meeting) => (
                        <Card key={meeting.id} className={styles.detailCard}>
                            <CardContent>
                                <Typography className={styles.subtitle}>
                                    <strong style={{ fontWeight: 'bolder' }}>Title:</strong>{' '}
                                    {meeting.title}
                                </Typography>
                                <Typography>
                                    <strong style={{ fontWeight: 'bolder' }}>Date:</strong> {meeting.date}
                                </Typography>
                                <Typography>
                                    <strong style={{ fontWeight: 'bolder' }}>Start Time:</strong>{' '}
                                    {meeting.start_time}
                                </Typography>
                                <Typography>
                                    <strong style={{ fontWeight: 'bolder' }}>End Time:</strong>{' '}
                                    {meeting.end_time}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <p>No meetings available.</p>
                )}
            </Box>
        );
    };

    const freeSlots = meetings.filter((meeting) => !meeting.is_reserved);
    const reservedSlots = attendances.map(att => att.meeting); // Assuming attendances contain meeting information

    return (
        <div className={styles.meetingInstructorContainer}>
            <h1 className={styles.title}>Instructor Coaching Sessions</h1>

            {/* MUI Tabs for switching between Free Slots and Reserved Meetings */}
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{ marginBottom: '25px', padding: '20px' }}
                textColor="primary"
                indicatorColor="primary"
                TabIndicatorProps={{ style: { display: 'none' } }}
            >
                <Tab
                    label="Free Slots"
                    sx={{
                        backgroundColor: tabValue === 0 ? '#aebdc5' : '#f0f0f0',
                        color: tabValue === 0 ? 'white !important' : 'black',
                        borderRadius: '8px 8px 0 0',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                    }}
                />
                <Tab
                    label="Reserved Meetings"
                    sx={{
                        backgroundColor: tabValue === 1 ? '#aebdc5' : '#f0f0f0',
                        color: tabValue === 1 ? 'white !important' : 'black',
                        borderRadius: '8px 8px 0 0',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                    }}
                />
            </Tabs>

            {/* Render content based on active tab */}
            {tabValue === 0 && renderMeetings(freeSlots)}
            {tabValue === 1 && renderMeetings(reservedSlots)}
        </div>
    );
};

export default MeetingInstructor;
