import React, { useState, useEffect } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Button,
    Typography,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Alert,
} from '@mui/material';
import { getToken } from '../../Helpers/constants';
import styles from './Meeting.module.css';

const MeetingPage = () => {
    const [allMeetings, setAllMeetings] = useState([]); // For "All Meetings" tab
    const [myMeetings, setMyMeetings] = useState([]); // For "My Meetings" tab
    const [finishedMeetings, setFinishedMeetings] = useState([]); // For "Finished Meetings" tab
    const [tabValue, setTabValue] = useState(0); // Tracks the active tab
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false); // Feedback popup state
    const [feedback, setFeedback] = useState(''); // Feedback input state
    const [rating, setRating] = useState(''); // Rating input state
    const [selectedMeetingId, setSelectedMeetingId] = useState(null); // Selected meeting for feedback
    const [errorMessage, setErrorMessage] = useState(''); // API error message
    const [successMessage, setSuccessMessage] = useState(''); // API success message
    const token = getToken();

    // Fetch data on mount and when tab changes
    useEffect(() => {
        if (tabValue === 0) {
            fetchAllMeetings();
        } else if (tabValue === 1) {
            fetchMyMeetings();
        } else if (tabValue === 2) {
            fetchFinishedMeetings();
        }
        // eslint-disable-next-line
    }, [tabValue, token]);

    // Fetch "All Meetings"
    const fetchAllMeetings = () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', `YourCSRFTokenHere`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch('https://depiback.eyouthlearning.com/api/meetings/', requestOptions)
            .then((response) => response.json())
            .then((result) => setAllMeetings(result))
            .catch((error) => console.error(error));
    };

    // Fetch "My Meetings"
    const fetchMyMeetings = () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', `YourCSRFTokenHere`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch('https://depiback.eyouthlearning.com/api/meetings/my_reserved_meetings/', requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setMyMeetings(result);
                // console.log('results', result)
            })
            .catch((error) => console.error(error));
    };

    // Fetch "Finished Meetings"
    const fetchFinishedMeetings = () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('X-CSRFToken', 'YourCSRFTokenHere');

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch('https://depiback.eyouthlearning.com/api/meetings/finished_meetings/', requestOptions)
            .then((response) => response.json())
            .then((result) => setFinishedMeetings(result))
            .catch((error) => console.error(error));
    };

    // Handle tab change
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Handle reservation for available meetings
    const handleReserve = (meetingId) => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('X-CSRFToken', 'YourCSRFTokenHere');

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({}), // Sending an empty body
            redirect: 'follow',
        };

        fetch(
            `https://depiback.eyouthlearning.com/api/meetings/${meetingId}/reserve_slot/`,
            requestOptions
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then((result) => {
                // Update the state with the latest data
                setAllMeetings((prevMeetings) =>
                    prevMeetings.map((meeting) =>
                        meeting.id === meetingId
                            ? { ...meeting, is_reserved: true, meeting_url: result.meeting_url }
                            : meeting
                    )
                );
                // Optionally, you might want to refetch myMeetings
                fetchMyMeetings();
            })
            .catch((error) => console.error(error));
    };

    // Handle feedback submission
    const handleFeedbackSubmit = () => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('X-CSRFToken', 'YourCSRFTokenHere');

        const raw = JSON.stringify({
            feedback,
            rating: Number(rating),
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(
            `https://depiback.eyouthlearning.com/api/meetings/${selectedMeetingId}/feedback/`,
            requestOptions
        )
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((err) => {
                        throw new Error(err.error);
                    });
                }
                return response.json();
            })
            .then(() => {
                setSuccessMessage('Feedback sent successfully!');
                setErrorMessage('');
                setOpenFeedbackDialog(false);
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setSuccessMessage('');
            });
    };

    // Open feedback dialog
    const handleFeedback = (meetingId) => {
        setSelectedMeetingId(meetingId);
        setOpenFeedbackDialog(true);
    };

    // Close feedback dialog
    const handleCloseFeedbackDialog = () => {
        setOpenFeedbackDialog(false);
        setFeedback('');
        setRating('');
        setErrorMessage('');
        setSuccessMessage('');
    };

    // Render meetings for the active tab
    const renderMeetings = (meetings, buttonLabel, buttonAction, showFeedback = false) => {
        return (
            <Box className={styles.box}>
                {meetings.map((meeting) => (
                    <Card key={meeting.id} className={styles.card}>
                        <CardContent>
                            <Typography className={styles.subtitle}>
                                <strong style={{ fontWeight: 'bolder' }}>Title:</strong>{' '}
                                {meeting.title}
                            </Typography>
                            <Typography className={styles.subtitle}>
                                <strong style={{ fontWeight: 'bolder' }}>Instructor:</strong>{' '}
                                {meeting.instructor}
                            </Typography>
                            <Typography>
                                <strong style={{ fontWeight: 'bolder' }}>Date:</strong> {meeting.date}
                            </Typography>
                            <Typography>
                                <strong style={{ fontWeight: 'bolder' }}>Start Time:</strong>{' '}
                                {meeting.start_time}
                            </Typography>
                            <Typography>
                                <strong style={{ fontWeight: 'bolder' }}>End Time:</strong>{' '}
                                {meeting.end_time}
                            </Typography>
                            <div className={styles.Buttons}>


                                {buttonLabel && (


                                    <Button
                                        className={styles.button}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => buttonAction(meeting)}
                                        disabled={meeting.is_reserved && buttonLabel === 'Reserve Now'} // Disable if meeting is reserved
                                        sx={{ marginTop: '10px' }}
                                    >
                                        {meeting.is_reserved && buttonLabel === 'Reserve Now'
                                            ? 'Meeting Reserved'
                                            : buttonLabel}
                                    </Button>
                                )}
                                {showFeedback && (
                                    <Button
                                        className={styles.button}
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleFeedback(meeting.id)}
                                        sx={{ marginTop: '10px', marginLeft: '10px' }}
                                    >
                                        Leave Feedback
                                    </Button>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        );
    };

    return (
        <div style={{ marginTop: '20px' }}>
            {/* MUI Tabs with custom styling */}
            <Tabs
                value={tabValue}
                onChange={handleChange}
                sx={{
                    marginBottom: '25px',
                    padding: '20px',
                }}
                textColor="primary"
                indicatorColor="primary"
                TabIndicatorProps={{ style: { display: 'none' } }} // Remove underline
            >
                <Tab
                    label="ALL Coaching sessions"
                    sx={{
                        backgroundColor: tabValue === 0 ? '#aebdc5' : '#f0f0f0',
                        color: tabValue === 0 ? 'white !important' : 'black',
                        borderRadius: '8px 8px 0 0',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                    }}
                />
                <Tab
                    label="My Coaching sessions"
                    sx={{
                        backgroundColor: tabValue === 1 ? '#aebdc5' : '#f0f0f0',
                        color: tabValue === 1 ? 'white !important' : 'black',
                        borderRadius: '8px 8px 0 0',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                    }}
                />
                <Tab
                    label="Finished Sessions"
                    sx={{
                        backgroundColor: tabValue === 2 ? '#aebdc5' : '#f0f0f0',
                        color: tabValue === 2 ? 'white !important' : 'black',
                        borderRadius: '8px 8px 0 0',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                    }}
                />
            </Tabs>

            {/* Show different data for each tab */}
            {tabValue === 0 &&
                renderMeetings(allMeetings, 'Reserve Now', (meeting) => handleReserve(meeting.id))}
            {tabValue === 1 &&
                renderMeetings(
                    myMeetings,
                    'Join Meeting',
                    (meeting) => window.open(meeting.meeting_url),
                    true
                )}
            {tabValue === 2 && renderMeetings(finishedMeetings)}

            {/* Feedback Dialog */}
            <Dialog open={openFeedbackDialog} onClose={handleCloseFeedbackDialog}>
                <DialogTitle>Leave Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide your feedback and rate the meeting.
                    </DialogContentText>
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    {successMessage && <Alert severity="success">{successMessage}</Alert>}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Feedback"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Rating (1-5)"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFeedbackDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleFeedbackSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MeetingPage;
