import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";
import InstructorNavbar from "./Components/InstructorNavbar/InstructorNavbar";
import LoginPage from "./Components/Login/Login";
import MeetingPage from "./Components/Meeting/Meeting";
import MeetingInstructor from './Components/MeetingInstructor/MeetingInstructor';
import MeNavbar from "./Components/MeNavbar/MeNavbar";
import Navbar from "./Components/Navbar/Navbar";
import StaffNavbar from './Components/StaffNavbar/StaffNavbar';
import TasksPage from "./Components/Tasks/Tasks";
import TasksInstructor from "./Components/TasksInstructor/TasksInstructor";
import WebinarPage from "./Components/Webinar/Webinar";
import { getRole, getToken } from "./Helpers/constants";
import FreelanceOpp from "./Pages/FreelanceOpp/FreelanceOpp";
import InstructorDashboard from "./Pages/InstructorDashboard/InstructorDashboard";
import InstructorDashboardPreview from './Pages/InstructorDashboardPreview/InstructorDashboardPreview';
import ProfileInstructor from "./Pages/ProfileInstructor/ProfileInstructor";
import ProfileUser from './Pages/ProfileUser/ProfileUser';
import StudentDashboard from "./Pages/StudentDashboard/StudentDashboard";
import StudentDashboardPreview from './Pages/StudentDashboardPreview/StudentDashboardPreview';
import StudentProfileStaffPreview from './Pages/StudentProfileStaffPreview/StudentProfileStaffPreview';
import ControlPanel from './Pages/ControlPanel/ControlPanel';
import Unauthorized from "./Components/Unauthorized/Unauthorized"; // Import unauthorized component


// Function to refresh token
const refreshToken = async (refreshTokenValue) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("X-CSRFToken", "BoxKNWiVfvhecV7czR1549o9zjixc0dpr3SQxbmiHjXUGgQpf3kjT3zpyHzFF8mJ");

  const raw = JSON.stringify({ refresh: refreshTokenValue });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch("https://depiback.eyouthlearning.com/api/accounts/token/refresh/", requestOptions);
    const result = await response.json();
    if (response.ok) {
      localStorage.setItem("access_token", result.access);
      localStorage.setItem("refresh_token", result.refresh);
      console.log("Token refreshed successfully!", result.access);
    } else {
      console.error("Failed to refresh token:", result);
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
  }
};
// ProtectedRoute Component to handle role-based access
function ProtectedRoute({ children, allowedRoles }) {
  const role = getRole(); // Get current user's role
  const navigate = useNavigate();
  const token = getToken(); // Ensure token exists

  useEffect(() => {
    if (!token) {
      navigate('/login'); // Redirect to login if no token
    }
  }, [token, navigate]);

  // If the user's role is not allowed, show Unauthorized page
  if (!allowedRoles.includes(role)) {
    return <Unauthorized />;
  }

  return children; // Return the content if authorized
}




function AppContent() {
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && window.location.pathname !== '/login') {
      navigate('/login');
    }
  }, [token, navigate]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        {/* Routes accessible by students */}
        <Route path="/user-profile" element={<ProtectedRoute allowedRoles={['student']}><ProfileUser /></ProtectedRoute>} />
        <Route path="/freelance" element={<ProtectedRoute allowedRoles={['student']}><FreelanceOpp /></ProtectedRoute>} />
        <Route path="/tasks" element={<ProtectedRoute allowedRoles={['student']}><TasksPage /></ProtectedRoute>} />
        <Route path="/meeting" element={<ProtectedRoute allowedRoles={['student']}><MeetingPage /></ProtectedRoute>} />
        <Route path="/webinar" element={<ProtectedRoute allowedRoles={['student']}><WebinarPage /></ProtectedRoute>} />

        {/* Routes accessible by instructors */}
        <Route path="/instructor-profile" element={<ProtectedRoute allowedRoles={['instructor']}><ProfileInstructor /></ProtectedRoute>} />
        <Route path="/instructor-tasks" element={<ProtectedRoute allowedRoles={['instructor']}><TasksInstructor /></ProtectedRoute>} />
        <Route path="/instructor-meeting" element={<ProtectedRoute allowedRoles={['instructor']}><MeetingInstructor /></ProtectedRoute>} />

        {/* Routes accessible by "me" */}
        <Route path="/instructors-dashboard" element={<ProtectedRoute allowedRoles={['me']}><InstructorDashboard /></ProtectedRoute>} />
        <Route path="/student-dashboard" element={<ProtectedRoute allowedRoles={['me']}><StudentDashboard /></ProtectedRoute>} />

        {/* Routes accessible by staff */}
        <Route path="/instructors-dashboard-preview" element={<ProtectedRoute allowedRoles={['staff']}><InstructorDashboardPreview /></ProtectedRoute>} />
        <Route path="/student-dashboard-preview" element={<ProtectedRoute allowedRoles={['staff']}><StudentDashboardPreview /></ProtectedRoute>} />
        <Route path="/student-profile/:id" element={<ProtectedRoute allowedRoles={['staff']}><StudentProfileStaffPreview /></ProtectedRoute>} />
        <Route path="/control-panel/*" element={<ProtectedRoute allowedRoles={['staff']}><ControlPanel /></ProtectedRoute>} />
        {/* <Route path="/upload-students" element={<ProtectedRoute allowedRoles={['staff']}><UploadStudents /></ProtectedRoute>} />
        <Route path="/add-webinar" element={<ProtectedRoute allowedRoles={['staff']}><AddWebinar /></ProtectedRoute>} />
        <Route path="/webinar-attendance" element={<ProtectedRoute allowedRoles={['staff']}><WebinarAttendance /></ProtectedRoute>} />
        <Route path="/add-meeting" element={<ProtectedRoute allowedRoles={['staff']}><AddMeeting /></ProtectedRoute>} /> */}


        {/* Unauthorized route */}
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>

    </>
  );
}

function App() {
  const role = getRole();

  const renderNavbar = () => {
    if (role === "me") {
      return <MeNavbar />;
    } else if (role === "instructor") {
      return <InstructorNavbar />;
    } else if (role === "student") {
      return <Navbar />;
    } else if (role === "staff") {
      return <StaffNavbar />;
    }
    return <Navbar />; // Default to student navbar if role is not defined
  };

  // Token refresh logic with useEffect
  useEffect(() => {
    const refreshTokenValue = localStorage.getItem("refresh_token");
    if (refreshTokenValue) {
      const interval = setInterval(() => {
        refreshToken(refreshTokenValue);
      }, 6 * 60 * 60 * 1000); // Refresh token every 6 hours

      return () => clearInterval(interval); // Clean up interval on unmount
    }
  }, []);

  return (
    <Router>
      {renderNavbar()}
      <AppContent />
    </Router>
  );
}

export default App;
