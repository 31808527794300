import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './StudentProfileStaffPreview.module.css';
import { getToken } from '../../Helpers/constants';

function StudentProfileStaffPreview() {
  const { id } = useParams(); // Get the student ID from the URL
  const [studentData, setStudentData] = useState(null);
  // eslint-disable-next-line 
  const [error, setError] = useState(null);
  const token = getToken();

  useEffect(() => {
    fetchStudentData(id);
    // eslint-disable-next-line 
  }, [id]);

  const fetchStudentData = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "JHwYKay4lvFUVf8STFN2Q29tEtxYK0xVmpYx3LYi9cfZqFh1p3q7iITTnkWYsngh");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://depiback.eyouthlearning.com/api/admin/students/${id}/`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch student data");
        }
        return response.json();
      })
      .then((result) => {
        setStudentData(result);
        // console.log('StudentProfileStaffPreview', result);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
      });
  };

  const safeArray = (arr) => Array.isArray(arr) ? arr : [];

  return (
    <>
      <h2 className={styles.title}>Student Profile</h2>
      <div className={styles.profileContainer}>
        {studentData ? (
          <div className={styles.studentInfo}>
            {/* Basic Info Section */}
            <div className={styles.infoCard}>
              <p><strong>ID:</strong> {studentData.id || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Full Name:</strong> {studentData.full_name || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Email:</strong> {studentData.email || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Phone:</strong> {studentData.phone || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Job Profile:</strong> {studentData.job_profile || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Coaching Round:</strong> {studentData.coaching_round || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Job Profile Round:</strong> {studentData.job_profile_round || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Instructor:</strong> {studentData.instructor || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Technical Provider:</strong> {studentData.technical_provider || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Track:</strong> {studentData.track || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Training Round:</strong> {studentData.training_round || "N/A"}</p>
            </div>
            <div className={styles.infoCard}>
              <p><strong>Total Freelance Income:</strong> {studentData.total_freelance_income || 0}</p>
            </div>

            {/* Tasks Section */}
            <div className={styles.tasksSection}>
              <h3>Tasks</h3>
              {safeArray(studentData.tasks).length > 0 ? (
                <ul className={styles.taskList}>
                  {safeArray(studentData.tasks).map((task) => (
                    <li key={task.id} className={styles.taskCard}>
                      <p><strong>Task Name:</strong> {task.name}</p>
                      <p><strong>Description:</strong> {task.description}</p>
                      <p><strong>Deadline:</strong> {new Date(task.deadline).toLocaleString()}</p>
                      <p><strong>Reviewed:</strong> {task.reviewed ? 'Yes' : 'No'}</p>
                      <p><strong>Degree:</strong> {task.degree}</p>
                      <p><strong>Comment:</strong> {task.comment || 'No Comment'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No tasks available</p>
              )}
            </div>

            {/* Freelance Jobs Section */}
            <div className={styles.freelanceSection}>
              <h3>Freelance Jobs</h3>
              {safeArray(studentData.freelance_jobs).length > 0 ? (
                <ul className={styles.freelanceList}>
                  {safeArray(studentData.freelance_jobs).map((job) => (
                    <li key={job.id} className={styles.freelanceCard}>
                      <p><strong>Job URL:</strong> <a href={job.url} target="_blank" rel="noopener noreferrer">{job.url}</a></p>
                      <p><strong>Status:</strong> {job.status}</p>
                      <p><strong>Price:</strong> {job.price}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No freelance jobs available</p>
              )}
            </div>

            {/* Meetings Section */}
            <div className={styles.meetingsSection}>
              <h3>Meetings</h3>
              {safeArray(studentData.meetings).length > 0 ? (
                <ul className={styles.meetingList}>
                  {safeArray(studentData.meetings).map((meeting) => (
                    <li key={meeting.id} className={styles.meetingCard}>
                      <p><strong>Meeting ID:</strong> {meeting.meeting}</p>
                      <p><strong>Joined:</strong> {meeting.joined ? 'Yes' : 'No'}</p>
                      <p><strong>Instructor Feedback:</strong> {meeting.instructor_feedback || 'None'}</p>
                      <p><strong>Student Feedback:</strong> {meeting.student_feedback || 'None'}</p>
                      <p><strong>Student Rating:</strong> {meeting.student_rating || 'N/A'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No meetings available</p>
              )}
            </div>

            {/* Webinars Section */}
            <div className={styles.webinarsSection}>
              <h3>Webinars</h3>
              {safeArray(studentData.webinars).length > 0 ? (
                <ul className={styles.webinarList}>
                  {safeArray(studentData.webinars).map((webinar, index) => (
                    <li key={index} className={styles.webinarCard}>
                      <p><strong>Webinar Name:</strong> {webinar.name}</p>
                      <p><strong>Joined:</strong> {webinar.joined ? 'Yes' : 'No'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No webinars available</p>
              )}
            </div>
          </div>
        ) : (
          <p>Loading student data...</p>
        )}
      </div>
    </>
  );
}

export default StudentProfileStaffPreview;
