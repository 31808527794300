import React, { useEffect, useState } from 'react';
import styles from './StudentDashboardPreview.module.css';
import { getToken } from '../../Helpers/constants';
import { Link } from 'react-router-dom';

function StudentDashboardPreview() {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordering, setOrdering] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const token = getToken();

  useEffect(() => {
    const fetchStudents = async () => {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append(
        'Authorization',
        `Bearer ${token}`
      );
      myHeaders.append('X-CSRFToken', 'dbulTVCCssdH35VRb2jUALKF7FHDu8ib2o2wyfbUuMzhZXQ0PLSvAgBOTQSwdOYi');

      const url = new URL('https://depiback.eyouthlearning.com/api/admin/students/');
      url.searchParams.append('coaching_rounds__code', searchTerm);
      url.searchParams.append('ordering', ordering);
      url.searchParams.append('page', page);
      url.searchParams.append('page_size', pageSize);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        setStudents(data.results);
        setTotalCount(data.count);
        // console.log(data.results);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
    // eslint-disable-next-line 
  }, [searchTerm, ordering, page, pageSize]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to first page when search term changes
  };

  const handleOrderingChange = (e) => {
    setOrdering(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalCount / pageSize);
    const pages = [];

    // Always show the first page
    pages.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={page === 1 ? styles.active : ''}
      >
        1
      </button>
    );

    // Show the dots if the current page is greater than 4
    if (page > 4) {
      pages.push(<span key="start-dots">.....</span>);
    }

    // Calculate start and end pages for the middle section
    const startPage = Math.max(2, page - 2);
    const endPage = Math.min(totalPages - 1, page + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={page === i ? styles.active : ''}
        >
          {i}
        </button>
      );
    }

    // Show the dots if we're not near the end
    if (page < totalPages - 3) {
      pages.push(<span key="end-dots">...</span>);
    }

    // Always show the last page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={page === totalPages ? styles.active : ''}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className={styles.container}>

      <div className={styles.controls}>
        <input
          type="text"
          placeholder="Search by coaching rounds"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select value={ordering} onChange={handleOrderingChange}>
          <option value="">Order by</option>
          <option value="meeting_count">Meeting Count</option>
          <option value="no_of_tasks">Number of Tasks</option>
        </select>
        <select value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5 per page</option>
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
        </select>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Coaching Round</th>
            <th>Job Profile Round</th>
            <th>Instructor</th>
            <th>Freelance Profiles</th>
            <th>Meeting Count</th>
            <th>Tasks Completed</th>
            <th>Actions</th>

          </tr>
        </thead>
        <tbody>
          {students?.map((student) => (
            <tr key={student.id}>
              <td> <Link to={`/student-profile/${student.id}`}> {student.id}</Link></td>
              <td>{student.full_name}</td>
              <td>{student.coaching_round}</td>
              <td>{student.job_profile_round}</td>
              <td>{student.instructor}</td>
              <td>{student.all_freelance_platforms_profile_links.join(', ')}</td>
              <td>{student.meeting_count}</td>
              <td>
                {student.no_of_finished_tasks} / {student.no_of_tasks}
              </td>
              <td> <Link to={`/student-profile/${student.id}`}> View Profile</Link></td>

            </tr>

          ))}
        </tbody>
      </table>

      <div className={styles.pagination}>
        {renderPagination()}
      </div>
    </div>
  );
}

export default StudentDashboardPreview;
