import React, { useEffect, useState } from 'react';
import styles from './InstructorDashboard.module.css';
import { getToken } from '../../Helpers/constants';

function InstructorDashboard() {
  const [instructors, setInstructors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordering, setOrdering] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const token = getToken();

  useEffect(() => {
    const fetchInstructors = async () => {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('X-CSRFToken', 'dbulTVCCssdH35VRb2jUALKF7FHDu8ib2o2wyfbUuMzhZXQ0PLSvAgBOTQSwdOYi');

      const url = new URL('https://depiback.eyouthlearning.com/api/admin/instructors/');
      url.searchParams.append('coaching_rounds__code', searchTerm);
      url.searchParams.append('ordering', ordering);
      url.searchParams.append('page', page);
      url.searchParams.append('page_size', pageSize);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        setInstructors(data.results);
        setTotalCount(data.count);
      } catch (error) {
        console.error('Error fetching instructors:', error);
      }
    };

    fetchInstructors();
  }, [searchTerm, ordering, page, pageSize, token]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to the first page when search changes
  };

  const handleOrderingChange = (e) => {
    setOrdering(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <input
          type="text"
          placeholder="Search by coaching rounds code..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select value={ordering} onChange={handleOrderingChange}>
          <option value="">Order by</option>
          <option value="total_meetings">Total Meetings</option>
          <option value="free_slots">Free Slots</option>
        </select>
        <select value={pageSize} onChange={handlePageSizeChange}>
          <option value={5}>5 per page</option>
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
        </select>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Coaching Rounds</th>
            <th>Total Meetings</th>
            <th>Free Slots</th>
            <th>Average Rating</th>
          </tr>
        </thead>
        <tbody>
          {instructors?.map((instructor) => (
            <tr key={instructor.id}>
              <td>{instructor.id}</td>
              <td>{instructor.name || 'N/A'}</td>
              <td>{instructor.phone || 'N/A'}</td>
              <td>{instructor.coaching_rounds?.join(', ') || 'N/A'}</td>
              <td>{instructor.total_meetings}</td>
              <td>{instructor.free_slots}</td>
              <td>{instructor.average_rating}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.pagination}>
        {Array.from({ length: Math.ceil(totalCount / pageSize) }, (_, i) => i + 1).map((pageNum) => (
          <button
            key={pageNum}
            onClick={() => handlePageChange(pageNum)}
            className={pageNum === page ? styles.active : ''}
          >
            {pageNum}
          </button>
        ))}
      </div>
    </div>
  );
}

export default InstructorDashboard;
