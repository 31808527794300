import React, { useState } from 'react';
import { getToken } from '../../Helpers/constants';
import styles from './UploadStudents.module.css';

function UploadStudents() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const token = getToken();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setMessage('');  // Clear messages on new file selection
  };

  const uploadFile = (apiUrl) => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "Grxnphd602rKgJyxPKP0CNZ3TStvCq8breUQzY65VJvuToOJ6ITvtJRn5TCCWaPc");

    const formData = new FormData();
    formData.append("file", selectedFile);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    };

    fetch(apiUrl, requestOptions)
      .then(response => response.json())  // Assuming the server responds with JSON
      .then(result => {
        if (result.detail) {
          setMessage(` ${result.detail}`);
        }
      })
      .catch(error => {
        setMessage(` ${error.message}`);
      });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Upload CSV Files</h1>
      {message && <p className={styles.message}>{message}</p>}
      <div className={styles.section}>
        <h2>Upload Instructors</h2>
        <input type="file" onChange={handleFileChange} className={styles.fileInput} />
        <button onClick={() => uploadFile("https://depiback.eyouthlearning.com/api/upload/instructors/")} className={styles.button}>Upload Instructor</button>
        <a href="/instructorExample.xlsx" download className={styles.downloadLink}>Download template</a>
      </div>
      <div className={styles.section}>
        <h2>Upload Coaching Rounds</h2>
        <input type="file" onChange={handleFileChange} className={styles.fileInput} />
        <button onClick={() => uploadFile("https://depiback.eyouthlearning.com/api/upload/coaching-rounds/")} className={styles.button}>Upload Coaching Round</button>
        <a href="/coachingExample.xlsx" download className={styles.downloadLink}>Download template</a>
      </div>
      <div className={styles.section}>
        <h2>Upload Students</h2>
        <input type="file" onChange={handleFileChange} className={styles.fileInput} />
        <button onClick={() => uploadFile("https://depiback.eyouthlearning.com/api/upload/students/")} className={styles.button}>Upload Student</button>
        <a href="/StudentExample.xlsx" download className={styles.downloadLink}>Download template</a>
      </div>
    </div>
  );
}

export default UploadStudents;
