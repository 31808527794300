import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo1 from '../../assets/AGEN.png';
import logo2 from '../../assets/MCEN.png';
import logo3 from '../../assets/depiEN.png';
import logo4 from '../../assets/logo2.png';
import SubNavbar from '../SubNavbar/SubNavbar';

const Navbar = () => {
    const location = useLocation();

    // Hide Navbar if pathname is "/login"
    if (location.pathname === '/login') {
        return null;
    }
    return (
        <>
            <nav className={styles.navbar}>
                <div className={styles.logoContainer}>
                    <img src={logo1} alt="Logo 1" className={styles.logo} />
                    <img src={logo2} alt="Logo 2" className={styles.logo2} />
                </div>
                <div className={styles.navLink}>
                    <NavLink
                        to="/user-profile"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Profile
                    </NavLink>
                    <NavLink
                        to="/meeting"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Coaching sessions
                    </NavLink>
                    <NavLink
                        to="/webinar"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Webinar
                    </NavLink>
                    <NavLink
                        to="/freelance"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Freelance
                    </NavLink>
                    <NavLink
                        to="/tasks"
                        className={({ isActive }) => isActive ? styles.active : undefined}
                    >
                        Tasks
                    </NavLink>
                </div>
                <div className={styles.logoContainer}>
                    <img src={logo3} alt="Logo 3" className={styles.logo} />
                    <img src={logo4} alt="Logo 4" className={styles.logo1} />
                </div>
            </nav>
            <SubNavbar />
        </>
    );
};

export default Navbar;
