import React, { useState, useEffect } from 'react';
import styles from './ProfileInstructor.module.css';
import { getToken } from '../../Helpers/constants';

function ProfileInstructor() {
    const [instructorData, setInstructorData] = useState(null);

    useEffect(() => {
        const token = getToken();
        fetchInstructorData(token);
    }, []);

    const fetchInstructorData = (token) => {
        const myHeaders = new Headers();
        myHeaders.append('accept', 'application/json');
        myHeaders.append('X-CSRFToken', 'xmhB9v3mimkr2b6h9gijvOYTeo2fsmKKmzPMOPCEkGG1Y31qNZRUvjP20zd8b2qR');
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch('https://depiback.eyouthlearning.com/api/accounts/profile/', requestOptions)
            .then((response) => response.json())
            .then((result) => setInstructorData(result))
            .catch((error) => console.error(error));
    };

    return (
        <>
            <h2 className={styles.title}>Instructor Profile</h2>
            <div className={styles.profileContainer}>
                {instructorData ? (
                    <div className={styles.userInfo}>
                        <div className={styles.userCard}>
                            <p><strong>Email:</strong> {instructorData?.user?.email || 'N/A'}</p>
                        </div>
                        <div className={styles.userCard}>
                            <p><strong>Instructor ID:</strong> {instructorData?.id || 'N/A'}</p>
                        </div>
                        <div className={styles.userCard}>
                            <p><strong>Name:</strong> {instructorData?.name || 'N/A'}</p>
                        </div>
                        <div className={styles.userCard}>
                            <p><strong>Phone:</strong> {instructorData?.phone || 'N/A'}</p>
                        </div>

                        {/* Display coaching rounds */}
                        <div className={styles.coachingRoundsCard}>
                            <p><strong>Coaching Rounds:</strong></p>
                            <ul className={styles.coachingRoundsList}>
                                {instructorData?.coaching_rounds?.length > 0 ? (
                                    instructorData.coaching_rounds.map((round, index) => (
                                        <li key={index} className={styles.coachingRoundItem}>
                                            {round.code} {/* Access the `code` property */}
                                        </li>
                                    ))
                                ) : (
                                    <li>No coaching rounds available.</li>
                                )}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <p>Loading instructor data...</p>
                )}
            </div>
        </>
    );
}

export default ProfileInstructor;
