import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Snackbar, Tabs, Tab, Card, CardContent, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import styles from './FreelanceOpp.module.css';
import { getToken } from '../../Helpers/constants';

const FreelanceOpp = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [myOpportunities, setMyOpportunities] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [price, setPrice] = useState(''); // New state for price
  const [status, setStatus] = useState('in_progress');
  const [statusProofScreenshot, setStatusProofScreenshot] = useState(null);
  const [statusChangeDate, setStatusChangeDate] = useState(dayjs());
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const token = getToken();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "OrWjw2QegIeU5hTGapFYBYGFEPsP8XfOzejMGJJdbpiEIW9SrnJtsUyZQQBWsHWP");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://depiback.eyouthlearning.com/api/freelance-opportunities/", requestOptions)
      .then(response => response.json())
      .then(result => {
        setOpportunities(result);
      })
      .catch(error => setError(error));
  }, [token]);

  useEffect(() => {
    fetch("https://depiback.eyouthlearning.com/api/freelance-jobs/", {
      method: "GET",
      headers: {
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "X-CSRFToken": "3OqpzgcoBBSaLcttFwNBvH11quLzhvNVOBNSJX5nwiWUoRJFWuR6mDTlCvUGBfuW"
      }
    })
      .then(response => response.json())
      .then(data => {
        setMyOpportunities(data)
        // console.log('data', data)
      })
      .catch(error => setError(error));
  }, [token]);

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => {
    setOpen(false);
    setUrl('');
    setPrice(''); // Clear price input when closing
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setStatusProofScreenshot(file ? file : null);
  };

  const handleAddJob = () => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-CSRFToken", "3OqpzgcoBBSaLcttFwNBvH11quLzhvNVOBNSJX5nwiWUoRJFWuR6mDTlCvUGBfuW");

    const formData = new FormData();
    formData.append('url', url);
    formData.append('status', status);
    formData.append('status_change_date', statusChangeDate.format('YYYY-MM-DD'));
    formData.append('price', price); // Include price in form data
    formData.append('status_proof_screenshot', statusProofScreenshot || '');

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };

    fetch("https://depiback.eyouthlearning.com/api/freelance-jobs/", requestOptions)
      .then(response => response.json())
      .then(result => {
        setSnackbarOpen(true);
        setOpen(false);
        setOpportunities(result);
      })
      .catch(error => setError(error));
  };

  const renderMyOpportunityCards = (data) => {
    return data.map((opportunity) => (
      <Card key={opportunity.id} className={styles.card}>
        <CardContent>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Status:</strong> {opportunity.status}</Typography>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Date:</strong> {opportunity.status_change_date}</Typography>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Price:</strong> {opportunity.price}</Typography>

          <a className={styles.opportunitiesLink} href={opportunity.url} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" color="primary">View Link</Button>
          </a>
        </CardContent>
      </Card>
    ));
  };

  const renderOpportunityCards = (data) => {
    return data.map((opportunity) => (
      <Card key={opportunity.id} className={styles.cardFreelanceJob}>
        <CardContent>
          <Typography variant="h6"><strong style={{ fontWeight: "bolder", textAlign: "center" }}>{opportunity.title}</strong></Typography>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Hiring Company:</strong> {opportunity.hiring_company}</Typography>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Category:</strong> {opportunity.category}</Typography>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Description:</strong> {opportunity.description}</Typography>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Status:</strong> {opportunity.status}</Typography>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Date:</strong> {opportunity.deadline}</Typography>
          <Typography variant="body2"><strong style={{ fontWeight: "bolder" }}>Price:</strong> {opportunity.price}</Typography>
          <a className={styles.opportunitiesLink} href={opportunity.url} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" color="primary">View Link</Button>
          </a>
        </CardContent>
      </Card>
    ));
  };

  return (
    <div className={styles.firstContainer}>
      <div className={styles.container}>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          aria-label="tabs"
          TabIndicatorProps={{ style: { display: "none" } }}
          className={styles.tabs}
        >
          <Tab
            label="Freelance Opportunities"
            sx={{
              backgroundColor: tabValue === 0 ? '#aebdc5' : '#f0f0f0',
              color: tabValue === 0 ? 'white !important' : 'black',
              borderRadius: '8px 8px 0 0',
              padding: '10px 20px',
              fontWeight: 'bold',
            }}
          />
          <Tab
            label="My Opportunities"
            sx={{
              backgroundColor: tabValue === 1 ? '#aebdc5' : '#f0f0f0',
              color: tabValue === 1 ? 'white !important' : 'black',
              borderRadius: '8px 8px 0 0',
              padding: '10px 20px',
              fontWeight: 'bold',
            }}
          />
        </Tabs>
        <div role="tabpanel" hidden={tabValue !== 0}>
          {error ? (
            <p className={styles.error}>Error fetching data: {error.message}</p>
          ) : (
            <div className={styles.cardContainer}>
              {opportunities.length > 0 ? renderOpportunityCards(opportunities) : <>No opportunities</>}
            </div>
          )}
        </div>
        <div role="tabpanel" hidden={tabValue !== 1}>
          {error ? (
            <p className={styles.error}>Error fetching data: {error.message}</p>
          ) : (
            <div className={styles.cardContainer}>
              {myOpportunities.length > 0 ? renderMyOpportunityCards(myOpportunities) : <>No opportunities</>}
            </div>
          )}
        </div>
        {/* Add Job Dialog */}
        <Dialog open={open} onClose={handleDialogClose}>
          <DialogTitle>Add Freelance Job</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Job URL"
              type="url"
              fullWidth
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <TextField
              select
              label="Status"
              fullWidth
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              margin="dense"
            >
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="finished">Finished</MenuItem>
            </TextField>

            {/* Date picker for status change date */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Status Change Date"
                value={statusChangeDate}
                onChange={(newValue) => setStatusChangeDate(dayjs(newValue))}
                renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
              />
            </LocalizationProvider>

            {/* Number input for price */}
            <TextField
              margin="dense"
              label="Price"
              type="number"
              fullWidth
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />

            {/* File input for image upload */}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ marginTop: '16px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddJob} color="primary">
              Add Job
            </Button>
          </DialogActions>
        </Dialog>

        {/* Success Snackbar */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          message="Job added successfully"
          onClose={() => setSnackbarOpen(false)}
        />
      </div>
      <aside className={styles.addJobLink}>
        <p className={styles.freelance_opportunities}>
          If you have freelance opportunities right now or<br /> if you completed one of them, add it now!
        </p>

        <Button className={styles.Add_Job_Link} variant="contained" color="primary" onClick={handleDialogOpen}>
          Add Job Link
        </Button>
      </aside>
    </div>
  );
};

export default FreelanceOpp;
